import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import './App.css'

const elementResizeDetectorMaker = require('element-resize-detector')
const erd = elementResizeDetectorMaker()

const { statistic, milestoneCount, hideLabel } = new Proxy(
    new URLSearchParams(window.location.search),
    {
        get: (searchParams, prop) => searchParams.get(prop)
    }
)

const milestone = {
    hideLabel: !!hideLabel,
    count: milestoneCount ? Number.parseInt(milestoneCount) : 555_000_000,
    statistic: statistic ?? 'kill_minion'
}
const defaultWidth = 404

const ProgressBar = ({ percentage, scale, width }) => {
    return (
        <div style={{ position: 'relative' }}>
            <div
                style={{
                    position: 'absolute',
                    left: `${((defaultWidth - width) / 2) * -1}px`,
                    width: `0`
                }}
            >
                <div
                    className="progress-container"
                    style={{ transform: `scale(${scale > 1 ? 1 : scale})` }}
                >
                    <div className="progress-container-left" />
                    <div className="progress-bar">
                        <Filler percentage={percentage} />
                    </div>
                    <div className="progress-container-right" />
                </div>
            </div>
        </div>
    )
}

const Filler = ({ percentage }) => {
    return (
        <>
            <div
                className={`filler pulsing${percentage >= 100 ? ' finished' : ''}`}
                style={{ width: `${percentage}%` }}
            />
            <div className="filler-effect" />
            <div className="gloss" />
        </>
    )
}

const Label = ({ completed, total, trembling, hide }) => {
    let label
    if (hide || completed === 0) {
        label = (
            <>
                <span className="label-hardcoded">&nbsp;</span>
            </>
        )
    } else if (completed >= total) {
        label = (
            <>
                <span className="label-completed">{total.toLocaleString()}</span>
            </>
        )
    } else {
        label = <>{`${completed.toLocaleString()} / ${total.toLocaleString()}`}</>
    }

    return <div className={`progress-title${trembling ? ' tremble' : ''}`}>{label}</div>
}

function App() {
    const [killCount, setKillCount] = useState(0)
    const [scale, setScale] = useState(1)
    const [width, setWidth] = useState(0)
    const [trembling, setTrembling] = useState(false)
    const containerRef = useRef(null)

    useEffect(() => {
        const ctnRef = containerRef.current

        erd.listenTo(ctnRef, (element) => {
            setWidth(element.offsetWidth)
            setScale(element.offsetWidth / defaultWidth)
        })

        return () => {
            erd.removeAllListeners(ctnRef)
        }
    }, [containerRef, killCount])

    useEffect(() => {
        let pollHandle = null
        let trembleHandle = null
        const domain = process.env.REACT_APP_HOSTED_ZONE_NAME ?? 'atoma.cloud'
        const subDomain = process.env.REACT_APP_ENV_NAME
            ? `counter-api-${process.env.REACT_APP_ENV_NAME}`
            : 'counter-api-prod'

        function poll() {
            axios
                .get(`https://${subDomain}.${domain}/getstatistic/${milestone.statistic}`)
                .then((result) =>
                    setKillCount((previous) => {
                        const next = parseInt(result.data)

                        if (previous !== 0 && previous !== next) {
                            setTrembling(true)
                            trembleHandle = setTimeout(() => {
                                setTrembling(false)
                            }, 2000)
                        }

                        return next
                    })
                )
                .then(() => {
                    pollHandle = setTimeout(poll, Math.random() * (15000 - 4800) + 4800)
                })
        }

        poll()

        return () => {
            clearTimeout(pollHandle)
            clearTimeout(trembleHandle)
        }
    }, [setKillCount])

    return (
        <div className="App">
            <header className="header" ref={containerRef}>
                <Label
                    completed={killCount}
                    total={milestone.count}
                    trembling={trembling}
                    hide={milestone.hideLabel}
                />
                <ProgressBar
                    percentage={(killCount / milestone.count) * 100}
                    scale={scale}
                    width={width}
                />
            </header>
        </div>
    )
}

export default App
